import { combineReducers } from "redux";
import wordReducer from './wordReducer';
import jokeReducer from './jokeReducer';

const initialState = {
      data: [],
      shuffled: [],
      loaded: false,
      placeholder: "Loading",
      solved: [],
      joke_number: 0,
};

function rootReducer(state = initialState, action) {
	switch (action.type) {
    	case "NEXT_JOKE": {
    		const joke_number = state.joke_number
    		return Object.assign({}, state, {joke_number: joke_number})
    	}
    	default:{
            return state
        }
    }
};

export default combineReducers({rootReducer, wordReducer, jokeReducer});
