import React, { Component } from 'react';
import DraggableSnap from "./DraggableSnap.jsx";
import WordBucket from "./WordBucket.jsx";
import { fetchJoke, fetchWordsForJoke , nextJoke,unsolve,toggle_mute,
  fetchAllJokes} from "../actions/jokeActions";
import { connect } from "react-redux";
import WordPallete from "./WordPallete.jsx";
import SetupHolder from "./SetupHolder.jsx";
import rimshot from "../badumtss.mp3";
import laughtrack from "../laughtrack.mp3"
import {wordPlacedSuccess,allWordsPlaced} from "../actions/wordActions";
import mute_svg from '../mute.svg';
import unmute_svg from '../unmute.svg';
import mute2 from '../simple_mute.svg'
import mute3 from '../simple_unmute.svg'

import "regenerator-runtime/runtime";

class Layout extends Component {
  async componentDidMount() {
    await this.props.dispatch(fetchAllJokes());
  	await this.props.dispatch(nextJoke());
    await this.get_hint()
  }

  async get_hint(){
  	const length = this.props.words_correct.filter(x => x === false).length
  	const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);
  	const word_to_place_index = Math.floor(Math.random() * length);
  	const word_to_place_number = indexOfAll(this.props.words_correct,false)[word_to_place_index]
  	const word_to_place = document.getElementById("visible_" + String(word_to_place_number))
    word_to_place.style.transitionDuration = "2s"
  	const where_to_place = document.getElementById("invisible_"+ String(word_to_place_number))
  	const destination_rect = where_to_place.getBoundingClientRect();
    const origin_rect = word_to_place.getBoundingClientRect();
    const move_x = destination_rect['x'] - origin_rect['x']
    const move_y = destination_rect['y'] - origin_rect['y']
    word_to_place.style.transform = "translate("+move_x+"px,"+move_y+"px)"
    this.props.dispatch(wordPlacedSuccess(word_to_place_number));
       this.props.dispatch(allWordsPlaced())
    word_to_place.ontransitionend = (word) => {
      word.currentTarget.style.visibility = 'hidden'
    }
    
  }

  async next_joke(){
      await this.props.dispatch(nextJoke());
      let words = document.getElementsByClassName("Draggable-Word");
      //return shuffled words to wordpallette
      Array.from(words).forEach(async(word) => {
        word.style.visibility = "visible"
        word.style.transitionDuration = "0s";
        word.style.transform = "";
      })
      let destinations = document.getElementsByClassName("Hidden-Word");
      //make answer invisible
      Array.from(destinations).forEach(async(word) => {
        word.style.color = "transparent"
      })
      await this.props.dispatch(unsolve())
      //to prevent hint moving from wrong position
      await new Promise(r => setTimeout(r, 50));
      await this.get_hint()
      
  }

  toggle_mute(){
    this.props.dispatch(toggle_mute())
  }

  render() {
  	let { error, shuffled_words, punchline_words } = this.props;
    if (error) {
  	  return <div>Error! {error.message}</div>;
    }
    let action_button;
    if (this.props.solved == 'visible'){
      let sound = new Audio(rimshot);
      sound.play();
      action_button = <button id='next_joke' onClick={() => this.next_joke()}>
      Click for next Joke</button>
    }
    else{
      action_button = <button id='get_hint' onClick={() =>  this.get_hint()}>Get Hint? 🤔</button>
    }
    let content_button;
    if (this.props.out_of_jokes){
      content_button = <div><h2>You're Done!</h2></div>
    }
    else{
      content_button = 
      <div>
        <SetupHolder setup={this.props.setup}/>
        <h4>The joke can be answered by re-arranging the words below, 
        drag them to the empty positions</h4>
        <WordPallete punchline_words={shuffled_words}/>
        {action_button }
        <div id='destinationElements'>
          {punchline_words.map(word=> {            
            return (
              <WordBucket key={word.order} word={word.word} order={'invisible_' + word.order}
              word_order={word.order}/>
              );
            })
          }
        </div>
      </div>
    }
    let mute_image = this.props.mute ? mute_svg : unmute_svg 
    return (
      <div>
        <div id="header" style={{display:'inline-block'}}>
          <div id="button_balancer"></div>
          <h1>Welcome to the Joke Scrambler</h1>
          <button id="mute_button" onClick={() => this.toggle_mute()}>
              <img src={this.props.mute ? mute2 : mute3}></img>
              <span>{this.props.mute ? "unmute" : "mute"}</span>
          </button>
        </div>
        {content_button}
        
        
  	  
  	    
    	</div>
    )
  }
}


const mapStateToProps = state => ({
  loading: state.jokeReducer.loading,
  error: state.jokeReducer.error,
  words_correct:state.jokeReducer.words_correct,
  solved:state.jokeReducer.solved,
  joke_id:state.jokeReducer.joke_id,
  words:state.jokeReducer.words,
  setup:state.jokeReducer.setup,
  punchline_words:state.jokeReducer.punchline_words,
  shuffled_words:state.jokeReducer.shuffled_words,
  dragged_word_number:state.wordReducer.dragged_word_number,
  mute: state.jokeReducer.mute,
  out_of_jokes: state.jokeReducer.out_of_jokes,
  jokes: state.jokeReducer.jokes,
});

export default connect(mapStateToProps)(Layout);