import { connect } from "react-redux";
import Layout from './Layout.jsx';
import { Provider } from "react-redux";
import { render } from "react-dom";
import React, { Component } from "react";
import store from "./store";
import "regenerator-runtime/runtime";


const container = document.getElementById("app");

render(
  <Provider store={store}>
    <Layout />
  </Provider>,
  container || document.createElement('div')
);
