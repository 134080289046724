import React, { Component } from 'react';
import { connect } from "react-redux";

class SetupHolder extends Component {
	render() {
		return(
			<div id='setup_holder'>
				{this.props.setup}
			</div>
			)
	}
}

export default SetupHolder;