import {
BUCKET_HOVER,
RESET_POSITION,
DRAGGING_WORD,
UNSOLVE
} from "../actions/wordActions";

const initialState = {
	position: { x:0, y:0 }, 
	placed:false,
    bucket_number:[],
    dragged_word_number:-1,
};

function wordReducer(state=initialState,action){
    switch(action.type){
        case 'BUCKET_HOVER':{
        	return Object.assign({}, state, {position: action.payload.position, 
                bucket_number:action.payload.bucket_number})
        }
        case 'RESET_POSITION':{
            return Object.assign({}, state, {position: { x:0, y:0 },bucket_number:[]})
        }
        case 'DRAGGING_WORD':{
            return Object.assign({}, state, {dragged_word_number:action.payload.word_number})
        }
        
        default:{
            return state
        }
    }
}
export default wordReducer;