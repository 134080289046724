import React, { Component } from 'react';
import { bucketHover} from "../actions/wordActions";
import { connect } from "react-redux";
import {
  wordPlacedSuccess,
  wordPlacedFailure,
  allWordsPlaced,
  draggingWord
} from "../actions/wordActions";
import laughtrack from "../laughtrack.mp3"
import rimshot from "../badumtss.mp3";
import "regenerator-runtime/runtime";

class WordBucket extends Component {
	constructor(props) {
	    super(props);
  }
  lightUp(event){
    if(this.props.dragged_word_number == this.props.word_order){
      event.target.style.backgroundColor = 'green';
    }
  	else if (!this.props.words_correct[this.props.word_order]){
      event.target.style.backgroundColor = 'red';
    }
  	const rect = event.target.getBoundingClientRect()
    const order_id = this.props.order
  	this.props.dispatch(bucketHover([rect.x,rect.y], Number(order_id.slice(order_id.indexOf('_')+1))))
  }
  unLightUp(event){
  	event.target.style.backgroundColor = '#FFFB8015';
  }
  checkWordPlacement(event){
    if(this.props.dragged_word_number == this.props.word_order){
      let moving_word = document.getElementById("visible_"+String(this.props.dragged_word_number));
      moving_word.style.visibility = "hidden";
      event.target.style.backgroundColor = '#5ead4454';
      this.props.dispatch(wordPlacedSuccess(this.props.dragged_word_number))
      let placed_word = document.getElementById("invisible_"+String(this.props.dragged_word_number))
      placed_word.style.color = '#8955D9';
      placed_word.style.border = "";
      this.props.dispatch(allWordsPlaced())
      if (this.props.solved == 'visible'){
        if (!this.props.mute){
          let sound = new Audio(laughtrack);
          sound.play();
        }
        return true
      }
      if (!this.props.mute){
        let sound = new Audio(rimshot);
        sound.play();
      }
      event.target.ontransitionend = (word) => {
        word.target.style.transitionDuration = '2s'
        word.target.style.backgroundColor = '#FFFB8015';
      }
      event.target.style.transitionDuration = '0.1s';
      return true
    }
    else if (!this.props.words_correct[this.props.word_order]){
      event.target.style.backgroundColor = 'red';
      event.target.ontransitionend = (word) => {
        word.target.style.transitionDuration = '5s'
        word.target.style.backgroundColor = '#FFFB8015';
      }
      event.target.style.transitionDuration = '0.1s';
    }
  }

  render() {
    return(
    	<div onDragOver={(event) => this.lightUp(event)} 
           onDragLeave={(event) => this.unLightUp(event)}
           onTouchStart={(event) => this.checkWordPlacement(event)}
    		   className='Hidden-Word' id={this.props.order}
           style={{color: this.props.words_correct[this.props.word_order] ? '#8955D9' : 'transparent' }}
           ref={ref => this.input = ref}> 
           {this.props.word} </div>
        	
    		)
	}
}

const mapStateToProps = state => ({
  dragged_word_number:state.wordReducer.dragged_word_number,
  words_correct:state.jokeReducer.words_correct,
})

export default connect(mapStateToProps)(WordBucket);