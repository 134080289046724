import React, { Component } from 'react';
import { connect } from "react-redux";
import DraggableSnap from "./DraggableSnap.jsx";
import { bucketHover, resetPosition} from "../actions/wordActions";

class WordPallete extends Component {
	reset(event){
		const rect = event.currentTarget.getBoundingClientRect()
		this.props.dispatch(bucketHover(this.props.position,[]))
		this.props.dispatch(resetPosition())
	}
	render() {
		return(
		    <div id='dragElements' onDragOver={(event) => this.reset(event)} >
	        {this.props.punchline_words.map(word => {
	          return (
	            <DraggableSnap key={word.order} word={word.word} number={word.order} 
	            order={'visible_'+word.order}/>
	            );
	          })
	        }
	      </div>
		)
	}
}
const mapStateToProps = state => ({
  position: state.wordReducer.position,
  bucket_number: state.wordReducer.bucket_number,
});

export default connect(mapStateToProps)(WordPallete);