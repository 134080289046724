import React from "react";
import { connect } from "react-redux";
import {
  wordPlacedSuccess,
  wordPlacedFailure,
  allWordsPlaced,
  draggingWord
} from "../actions/wordActions";
import laughtrack from "../laughtrack.mp3"
import rimshot from "../badumtss.mp3";
import "regenerator-runtime/runtime";

class DraggableSnap extends React.Component {
  constructor(props) {
    super(props);
  }
  set_dragging_word(event){
    this.props.dispatch(draggingWord(this.props.number))
    event.target.style.border = "0.15em solid black"
  }
  componentDidMount() {
    this.input.addEventListener('touchmove', this.touch_move, { passive: false });
  }
  //ensure this returns false when we don't match a draggable with the correct wordbucket
  check_drag_match(draggable_element_number,event){
    if (draggable_element_number == this.props.bucket_number){
        this.props.dispatch(wordPlacedSuccess(this.props.bucket_number))
        event.currentTarget.style.visibility = "hidden"
        let placed_word = document.getElementById("invisible_"+String(draggable_element_number))
        placed_word.style.color = '#8955D9';
        placed_word.style.border = "";
        this.props.dispatch(allWordsPlaced())
        if (this.props.solved == 'visible'){
          if (!this.props.mute){
            let sound = new Audio(rimshot);
            sound.play();
          }
          return true
        }
        return true
      }
      this.props.dispatch(wordPlacedFailure(this.props.bucket_number))
      return false
  }

  async snap(event){
    event.target.style.border = ""
    if (!(Array.isArray(this.props.bucket_number))) {
      const draggable_element_number = Number(event.target.id.substring(event.target.id.indexOf("_")+1))
      this.check_drag_match(draggable_element_number,event)
      
    }
    else{
      event.currentTarget.style.position = ""
      this.props.dispatch(wordPlacedFailure(this.props.bucket_number))
    }
    this.props.dispatch(draggingWord(-1))
    
  }
  touch_snap(event){
    let location = event.changedTouches[0]
    let target = document.elementFromPoint(location.pageX, location.pageY);
    if (target){
      const draggable_element_number = Number(event.target.id.substring(event.target.id.indexOf("_")+1))
      if(!this.check_drag_match(draggable_element_number,event)){
        event.target.style.position = "relative";
        event.target.style.left = "0px";
        event.target.style.top = "0px";
        event.target.style.border = ""
      }
    }
  }
  touch_move(event){
    event.preventDefault()
    let location = event.changedTouches[0]
    event.target.style.position = "absolute";
    event.target.style.left = String(location.pageX)+"px";
    event.target.style.top = String(location.pageY)+"px";
    let touch_location_element = document.elementFromPoint(location.pageX, location.pageY);
  }
  render() {
    return(
       <div className='Draggable-Word' draggable='true'  id={this.props.order}
        onDragStart={(event) => this.set_dragging_word(event)} 
        onDragEnd={(event) => this.snap(event)} 
        
        onTouchStart={(event) => this.set_dragging_word(event)}
        ref={ref => this.input = ref}>
        {this.props.word}
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  position: state.wordReducer.position,
  bucket_number: state.wordReducer.bucket_number,
  solved: state.jokeReducer.solved,
  words_correct:state.jokeReducer.words_correct,
  mute: state.jokeReducer.mute
});

export default connect(mapStateToProps)(DraggableSnap);


