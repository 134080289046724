export const WORD_PLACED_SUCCESS =
  "WORD_PLACED_SUCCESS";
export const WORD_PLACED_FAILURE =
  "WORD_PLACED_FAILURE";
export const ALL_WORDS_PLACED =
  "ALL_WORDS_PLACED";

export const BUCKET_HOVER =
	"BUCKET_HOVER";

export const RESET_POSITION = 
  "RESET_POSITION";

export const DRAGGING_WORD =
  "DRAGGING_WORD";

export const draggingWord = (word_number) => ({
  type: DRAGGING_WORD,
  payload: {word_number},
})
export const bucketHover = (position,bucket_number) => ({
	type: BUCKET_HOVER,
	payload: {position , bucket_number}
})

export const wordPlacedSuccess = order => ({
  type: WORD_PLACED_SUCCESS,
  payload: { order }
});

export const wordPlacedFailure = order => ({
  type: WORD_PLACED_FAILURE,
  payload: { order }
});

export const allWordsPlaced = () => ({
	type: ALL_WORDS_PLACED,
})

export const resetPosition = () => ({
  type: RESET_POSITION
})

