import {
  UNSOLVE,
  TOGGLE_MUTE,
  FETCH_ALL_JOKES_SUCCESS,
  FETCH_ALL_JOKES_FAILURE,
  NEXT_JOKE
} from "../actions/jokeActions";
import {
  WORD_PLACED_SUCCESS,
  WORD_PLACED_FAILURE,
  ALL_WORDS_PLACED
} from "../actions/wordActions";

const initialState = {
  jokes: [],
  words_correct: [],
  words: [],
  setup: "",
  punchline_words: [],
  shuffled_words: [],
  error: null,
  solved: 'hidden',
  joke_id: 1,
  mute: false,
  out_of_jokes:false,
  setup_length: -1,
};

export default function jokeReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_ALL_JOKES_SUCCESS:
      let lengths = []
      Object.keys(action.payload.all_jokes['all_jokes']).forEach((key) => {lengths.push(Number(key))})
      return Object.assign({}, state, {jokes: action.payload.all_jokes['all_jokes'],setup_length:lengths[0]})
    case NEXT_JOKE:
      let punchline_words,words_correct,shuffled,setup,new_jokes
      let setup_length_jokes = state.jokes[state.setup_length].slice(0)
      if (state.jokes[state.setup_length].length){
        punchline_words = setup_length_jokes[0]['words']
        words_correct = Array(punchline_words.length).fill(false);
        shuffled = [...punchline_words].sort(() => Math.random() - 0.5)
        setup = setup_length_jokes[0]['setup']
        setup_length_jokes = state.jokes[state.setup_length].slice(1)
        new_jokes = state.jokes
        new_jokes[state.setup_length] = setup_length_jokes
        return Object.assign({}, state, {jokes: new_jokes,punchline_words:punchline_words,
          shuffled_words:shuffled, words_correct:words_correct,setup:setup})
      }
      else if (state.jokes[state.setup_length+1].length){
         let new_length = state.setup_length+1
         setup_length_jokes = state.jokes[new_length].slice()
         punchline_words = setup_length_jokes[0].words
         words_correct = Array(punchline_words.length).fill(false);
         shuffled = [...punchline_words].sort(() => Math.random() - 0.5)
         setup = setup_length_jokes['setup']
         setup_length_jokes = state.jokes[state.setup_length].slice(1)
        new_jokes = state.jokes
        new_jokes[state.setup_length] = setup_length_jokes
        return Object.assign({}, state, {jokes: new_jokes,setup_length:new_length,
          punchline_words:punchline_words,
          shuffled_words:shuffled, words_correct:words_correct,setup:setup})
      }
      else{
        return Object.assign({}, state, {out_of_jokes:true})
      }
    case FETCH_ALL_JOKES_FAILURE:
      return Object.assign({}, state, {error: action.payload,jokes: []})
    case TOGGLE_MUTE:
      const mute_state = state.mute
      return Object.assign({}, state, {mute: !mute_state})
    case WORD_PLACED_SUCCESS:
      const new_words_correct = state.words_correct.slice();
      new_words_correct[action.payload.order] = true;
      return Object.assign({}, state, {words_correct:new_words_correct})
    case WORD_PLACED_FAILURE:
      const words_incorrect = state.words_correct.slice();
      words_incorrect[action.payload.order] = false;
      return Object.assign({}, state, {words_correct:words_incorrect})
    case ALL_WORDS_PLACED:
      words_correct =  state.words_correct.slice();
      for(let i=0; i<words_correct.length; i++){
        if (words_correct[i] == false){
          return Object.assign({}, state, {solved:'hidden'})
        }
      }
      return Object.assign({}, state, {solved:'visible'})
    case NEXT_JOKE:
      const joke_id = state.joke_id + 1
      return Object.assign({}, state, {joke_id:joke_id})
    case UNSOLVE:
      return Object.assign({}, state, {solved:'hidden'})
    default:
      return state;
  }
}
