const fetch = require("node-fetch");

//TODO add filter to get jokes user hasn't seen
export const fetchJoke = (joke_id) => {
  return dispatch => {
    return fetch("api/joke?joke_id=" + joke_id,
      {method:"GET"})
    .then(handleErrors) 
    .then(res => res.json())
    .then(json => {
      if (json['status']){
        dispatch(outOfJokes())
      }
      else{
        dispatch(fetchJokeSuccess(json))
        return json;
      }
      })
      .catch(error => dispatch(fetchJokeFailure(error)));
    };
  }   

export const fetchAllJokes = () => {
  return dispatch => {
    return fetch("api/all_jokes",{method:"GET"})
    .then(handleErrors) 
    .then(res => res.json())
    .then(json => {
      dispatch(fetchAllJokesSuccess(json))
        return json;
      })
      .catch(error => dispatch(fetchAllJokesFailure(error)));
  };
}
 
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const NEXT_JOKE = 
  "NEXT_JOKE"
export const UNSOLVE =
  "UNSOLVE";
export const TOGGLE_MUTE =
  "TOGGLE_MUTE";
export const OUT_OF_JOKES =
  "OUT_OF_JOKES";
export const FETCH_ALL_JOKES_SUCCESS =
  "FETCH_ALL_JOKES_SUCCESS";
export const FETCH_ALL_JOKES_FAILURE =
  "FETCH_ALL_JOKES_FAILURE";

export const unsolve = () => ({
  type: UNSOLVE
})

export const toggle_mute = () => ({
  type: TOGGLE_MUTE
});

export const fetchAllJokesSuccess = (all_jokes) => ({
  type: FETCH_ALL_JOKES_SUCCESS,
  payload: { all_jokes }
});

export const fetchAllJokesFailure = error => ({
  type: FETCH_ALL_JOKES_FAILURE,
  payload: { error }
})

export const nextJoke = () => ({
  type: NEXT_JOKE,
})